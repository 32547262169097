<!--电网状态-->

<template>
    <widget-framework v-if="dataLoaded" title="故障监控" :show-title="false" :show-decoration="false" :show-border="false">
        <template #content>
            <div class="container" >
                <monitor-item title="安全隐患" :detail="securityRisksDetail" :items="securityRisks" :column-width="[40, 200, 150, 200]"></monitor-item>
                <!--:items="alarmDevices"-->
                <!--设置报警设备取值为空-->
                <monitor-item title="报警设备" :detail="securityRisksDetail"  :column-width="[40, 200, 150, 200]"></monitor-item>
                <monitor-item title="故障设备" :detail="malfunctioningDevicesDetail" :items="malfunctioningDevices" :column-width="[40, 200, 150, 200]"></monitor-item>
                <monitor-item title="停电用户" :detail="powerCutUsersDetail" :items="powerCutUsers" :column-width="[40, 300, 200]"></monitor-item>
            </div>
        </template>
    </widget-framework>
    <dv-loading v-else></dv-loading>
</template>

<script>
import WidgetFramework from '@/components/widgets/WidgetFramework';
import MonitorItem from './MonitorItem';
import WidgetBase from '@/components/widgets/WidgetBase';
import EchartBase from '@/components/widgets/EchartBase';
import {
    green,
    cyan,
    red
} from '../../constant';

export default {
    name: 'FaultMonitor',
    components: {
        WidgetFramework,
        MonitorItem
    },
    mixins: [WidgetBase, EchartBase],
    data() {
        return {
            dataLoaded: false,
            alarmDevices: [],
            malfunctioningDevices: [],
            powerCutUsers: [],
            securityRisks: [],
            alarmDevicesDetail: [],
            malfunctioningDevicesDetail: [],
            powerCutUsersDetail: [],
            securityRisksDetail: [],
        };
    },
    mounted() {
        this.$client.getFaultMonitor().then((ret) => {
            const {data} = ret;
            //
            /*this.alarmDevicesDetail = (data.alarmDevices || []).map(item => {
                const deviceNameStr = item.deviceName;
                const tagNameStr = item.tagName;
                let currentValue = '';
                // 数据类型(1浮点 2布尔 3整型 4字符串)
                if (item.dataType === 1) {
                    currentValue = item.dataValueDouble;
                } else if (item.dataType === 2) {
                    currentValue = item.dataValueBoolean === 1 ? 'True' : 'False';
                } else if (item.dataType === 3) {
                    currentValue = item.dataValueInt;
                } else if (item.dataType === 4) {
                    currentValue = item.dataValueString;
                }
                const currentValueStr = currentValue;
                const normalValueStr = item.baseDataValue;
                return [deviceNameStr, tagNameStr, currentValueStr + normalValueStr];
            });
            this.malfunctioningDevicesDetail = (data.malfunctioningDevices || []).map(item => {
                const nameStr = item.patrolEquipment;
                const numberStr = item.assignmentAddr;
                const categoryStr = item.patrolResultNote;
                return [nameStr, numberStr, categoryStr];
            });
            this.powerCutUsersDetail = (data.powerCutUsers || []).map(item => {
                const nameStr = item.name;
                const startTimeStr = item.startTime;
                return [nameStr, startTimeStr];
            });
            this.securityRisksDetail = (data.securityRisks || []).map(item => {
                const nameStr = item.patrolEquipment;
                const numberStr = item.assignmentAddr;
                const categoryStr = item.flawLevelName;
                return [nameStr, numberStr, categoryStr];
            });*/
            //
            this.alarmDevices = (data.alarmDevices || []).map(item => {
                const deviceNameStr = `<span style="color: ${cyan}">${item.deviceName}</span>`;
                const tagNameStr = `<span style="color: ${cyan}">${item.tagName}</span>`;
                let currentValue = '';
                // 数据类型(1浮点 2布尔 3整型 4字符串)
                if (item.dataType === 1) {
                    currentValue = item.dataValueDouble;
                } else if (item.dataType === 2) {
                    currentValue = item.dataValueBoolean === 1 ? 'True' : 'False';
                } else if (item.dataType === 3) {
                    currentValue = item.dataValueInt;
                } else if (item.dataType === 4) {
                    currentValue = item.dataValueString;
                }
                const currentValueStr = `<span style="color: ${red}">${currentValue}</span>`;
                const normalValueStr = `<span style="color: ${green}">(${item.baseDataValue})</span><br/>`;
                return [deviceNameStr, tagNameStr, currentValueStr + normalValueStr];
            });
            this.malfunctioningDevices = (data.malfunctioningDevices || []).map(item => {
                const nameStr = `<span style="color: ${cyan}">${item.patrolEquipment}</span>`;
                const numberStr = `<span style="color: ${cyan}">${item.assignmentAddr}</span>`;
                const categoryStr = `<span style="color: ${red}">${item.patrolResultNote}</span><br/>`;
                return [nameStr, numberStr, categoryStr];
            });
            this.powerCutUsers = (data.powerCutUsers || []).map(item => {
                const nameStr = `<span style="color: ${green}">${item.name}</span>`;
                const startTimeStr = `<span style="color: ${green}">${item.startTime}</span><br/>`;
                return [nameStr, startTimeStr];
            });
            this.securityRisks = (data.securityRisks || []).map(item => {
                const nameStr = `<span style="color: ${cyan}">${item.patrolEquipment}</span>`;
                const numberStr = `<span style="color: ${cyan}">${item.assignmentAddr}</span>`;
                const categoryStr = `<span style="color: ${red}">${item.flawLevelName}</span><br/>`;
                return [nameStr, numberStr, categoryStr];
            });
            this.dataLoaded = true;
        });
    }
};
</script>

<style lang="less" scoped>
@import '~@/assets/style/variables.less';

.container {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

</style>
