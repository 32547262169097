<template>
    <div class="monitor-item">
        <div class="item-title">{{title}}</div>
        <dv-border-box-8 class="item-list">
            <!--<el-tooltip placement="top" :enterable="true" >
                <div class="board"
                     slot="content"
                     v-for="(item, index) in detail"
                     :key="index"
                >
                        {{index+1}}: {{item}}
                </div>-->
            <dv-scroll-board :config="config" ref="scrollBoard" class="board" v-if="items && items.length > 0"/>
                <div v-else class="empty">
                    暂无数据
                </div>
            <!--</el-tooltip>-->
        </dv-border-box-8>
    </div>
</template>

<script>
import {evenRowBGC, headerBGC, oddRowBGC} from '@/components/widgets/constant';

export default {
    name: 'MonitorItem',
    props: {
        title: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        },
        detail: {
            type: Array,
            default: () => []
        },
        columnWidth: {
            type: Array,
            default: null
        },
    },
    data() {
        return {
            config: {
                headerBGC,
                oddRowBGC,
                evenRowBGC,
                data: this.items,
                detail: this.detail,
                index: true,
                indexHeader: '',
                columnWidth: this.columnWidth,
                align: ['center'],
                carousel: 'single',
                rowNum: 2,
            }
        };
    },
};
</script>

<style lang="less" scoped>
.monitor-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;

    .item-title {
        width: 100%;
        font-size: 20px;
        padding-bottom: 5px;
    }

    .item-list {
        width: 100%;
        height: 70px;

        .board {
            height: 100%;
        }

        .empty {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
</style>
